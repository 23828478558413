import { BaseModel } from 'modules/core/models/base-model';

export class BMTestApplicationLocation extends BaseModel {
    id                           = null;
    lot_construction_id          = null;
    o_e_lot_construction_data_id = null;
    pks_s                        = null;
    pks_f                        = null;
    pkf_s                        = null;
    pkf_f                        = null;
    track_id                     = null;
    observations                 = null;
    bm_test_id                   = null;
}
