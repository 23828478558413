import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ManufacturingPlantTranspositionCompositionRepository extends BaseRepository {
    baseUrl = 'bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions';

    /**
     * Returns the total of the manufacturing plant transposition fractions
     *
     * @param manufacturingPlantTranspositionId
     * @return {*}
     */
    calculateTotal(manufacturingPlantTranspositionId) {
        return this.httpClient.get(`${this.baseUrl}/calculate-total/${manufacturingPlantTranspositionId}`);
    }

    /**
     * Saves the lines of the manufacturing plant transposition fractions
     *
     * @param manufacturingPlantTranspositionId
     * @param lines
     * @return {*}
     */
    saveLines(manufacturingPlantTranspositionId, lines) {
        return this.httpClient.patch(`${this.baseUrl}/save-lines/${manufacturingPlantTranspositionId}`, lines);
    }
}
