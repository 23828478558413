import { DialogService }                              from 'aurelia-dialog';
import { inject }                                     from 'aurelia-framework';
import { AggregatesLabTestsRepository }               from 'modules/aggregates/lab/services/repository';
import { FormulationStudiesRepository }               from 'modules/bituminous-mixtures/mixtures/formulation-studies/services/repository';
import { ManufacturingPlantTranspositionsRepository } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/services/repository';
import { ManufacturingPlantTransposition }            from 'modules/bituminous-mixtures/models/manufacturing-plant-transposition';
import { AdditionalInformationsRepository }           from 'modules/management/additional-information/additional-informations/services/repository';
import { ViewModalAdditionalInformation }             from 'modules/management/additional-information/additional-informations/view/index-modal';
import { LayersRepository }                           from 'modules/management/bituminous-mixtures/layers/services/repository';
import { MaterialTypesRepository }                    from 'modules/management/dynamic-fields/material-types/services/repository';

@inject(ManufacturingPlantTranspositionsRepository, MaterialTypesRepository, LayersRepository, AdditionalInformationsRepository, FormulationStudiesRepository, AggregatesLabTestsRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param manufacturingPlantTranspositionsRepository
     * @param materialTypesRepository
     * @param layersRepository
     * @param additionalInformationRepository
     * @param formulationStudiesRepository
     * @param aggregatesLabTestsRepository
     * @param dialogService
     */
    constructor(manufacturingPlantTranspositionsRepository, materialTypesRepository, layersRepository, additionalInformationRepository, formulationStudiesRepository, aggregatesLabTestsRepository, dialogService) {
        this.repository                      = manufacturingPlantTranspositionsRepository;
        this.materialTypesRepository         = materialTypesRepository;
        this.layersRepository                = layersRepository;
        this.additionalInformationRepository = additionalInformationRepository;
        this.formulationStudiesRepository    = formulationStudiesRepository;
        this.aggregatesLabTestsRepository    = aggregatesLabTestsRepository;
        this.dialogService                   = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ManufacturingPlantTransposition}
     */
    model() {
        let model = new ManufacturingPlantTransposition();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema for lot info
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    lotInfoSchema(viewModel, readonly = false) {
        this.concession_name = {
            type:       'text',
            key:        'concession_name',
            label:      'form.field.concession',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.entity_team_name = {
            type:       'text',
            key:        'entity_team_name',
            label:      'form.field.entity-team',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.highway_name = {
            type:       'text',
            key:        'highway_name',
            label:      'form.field.highway',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.intervention_type_name = {
            type:       'text',
            key:        'intervention_type_name',
            label:      'form.field.intervention-type',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        this.lot_intervention_name = {
            type:       'textarea',
            key:        'lot_intervention_name',
            label:      'form.field.lot-intervention',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
                disabled: readonly,
            },
        };

        return [
            [this.concession_name, this.highway_name],
            [this.intervention_type_name, this.entity_team_name],
            [this.lot_intervention_name],
        ];
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.number = {
            type:       'int',
            key:        'number',
            label:      'form.field.tpcf-number',
            size:       3,
            required:   false,
            attributes: {
                disabled: true,
            },
        };

        this.date = {
            type:       'material-ui-date-picker',
            key:        'date',
            label:      'form.field.date',
            size:       5,
            attributes: {
                disabled: readonly,
            },
        };

        this.bm_formulation_study_id = {
            type:         'select2',
            key:          'bm_formulation_study_id',
            label:        'form.field.associated-study-number',
            size:         8,
            remoteSource: () => this.formulationStudiesRepository.validatedByCurrentLotIntervention(),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue) {
                        this.formulationStudiesRepository.find(newValue).then((formulationStudy) => {
                            viewModel.model.concession_name        = formulationStudy.concession_name;
                            viewModel.model.highway_name           = formulationStudy.highway_name;
                            viewModel.model.entity_team_name       = formulationStudy.entity_team_name;
                            viewModel.model.intervention_type_name = formulationStudy.intervention_type_name;
                            viewModel.model.lot_intervention_name  = formulationStudy.lot_intervention_name;

                            viewModel.model.material_type_id    = formulationStudy.material_type_id;
                            viewModel.model.layer_id            = formulationStudy.layer_id;
                            viewModel.model.lot_intervention_id = formulationStudy.lot_intervention_id;
                            viewModel.model.team_id             = formulationStudy.team_id;

                            viewModel.linesData = formulationStudy.fillerCompositionData;
                        });
                    } else {
                        viewModel.model.concession_name        = '';
                        viewModel.model.highway_name           = '';
                        viewModel.model.entity_team_name       = '';
                        viewModel.model.intervention_type_name = '';
                        viewModel.model.lot_intervention_name  = '';

                        viewModel.model.material_type_id    = null;
                        viewModel.model.layer_id            = null;
                        viewModel.model.lot_intervention_id = null;
                        viewModel.model.team_id             = null;

                        viewModel.linesData = [];
                    }
                },
            ],
        };

        this.material_type_id = {
            type:         'select2',
            key:          'material_type_id',
            label:        'form.field.material-type',
            size:         6,
            required:     false,
            remoteSource: () => this.materialTypesRepository.all(),
            attributes:   {
                disabled: true,
            },
        };

        this.layer_id = {
            type:         'select2',
            key:          'layer_id',
            label:        'form.field.layer',
            size:         6,
            remoteSource: () => this.layersRepository.all(),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        this.supplier = {
            type:       'text',
            key:        'supplier',
            label:      'form.field.supplier',
            size:       8,
            attributes: {
                disabled: readonly,
            },
        };

        this.central_type = {
            type:       'text',
            key:        'central_type',
            label:      'form.field.central-type',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.central_location = {
            type:       'text',
            key:        'central_location',
            label:      'form.field.central-location',
            required:   false,
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        const schema = [
            [this.number, this.date],
            [this.bm_formulation_study_id],
            [this.material_type_id, this.layer_id],
            [this.supplier],
            [this.central_type, this.central_location],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    disabled: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    disabled: true,
                },
            };

            this.validated_by = {
                type:       'text',
                key:        'validated_by',
                label:      'form.field.validated-by',
                size:       6,
                required:   false,
                hidden:     !viewModel.model.is_validated,
                attributes: {
                    disabled: true,
                },
            };

            this.validated_at = {
                type:       'text',
                key:        'validated_at',
                label:      'form.field.validated-at',
                size:       6,
                required:   false,
                hidden:     !viewModel.model.is_validated,
                attributes: {
                    disabled: true,
                },
            };

            return [...schema, [this.created_by, this.created_at], [this.validated_by, this.validated_at]];
        }

        return schema;
    }

    /**
     * Returns form schema for additional information
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    additionalInformationSchema(viewModel, readonly = false) {
        this.additional_informations = {
            type:              'duallistbox',
            key:               'additional_informations',
            label:             'form.field.additional-information',
            remoteSource:      () => this.additionalInformationRepository.searchMap(),
            handleDoubleClick: (selectedOption) => {
                if (selectedOption.id) {
                    this.dialogService
                        .open({
                            viewModel: ViewModalAdditionalInformation,
                            model:     { id: selectedOption.id },
                        });
                }
            },
            size:              12,
            attributes:        {
                disabled: readonly,
            },
        };

        return [
            [this.additional_informations],
        ];
    }

    /**
     * Returns form schema for additional information
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    mixtureSchema(viewModel, readonly = false) {
        this.aggregate_lab_test_id = {
            type:         'select2',
            key:          'aggregate_lab_test_id',
            label:        'form.field.sample-number',
            remoteSource: () => this.aggregatesLabTestsRepository.getTTMCbyCurrentLotIntervention(),
            size:         12,
            attributes:   {
                disabled: readonly,
            },
        };

        return [
            [this.aggregate_lab_test_id],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('bituminous-mixtures.mixtures.manufacturing-plant-transpositions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
