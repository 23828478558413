import { BaseTestHeader } from 'modules/aggregates/models/base-test-header';

export class BMTest extends BaseTestHeader {
    // selected bulletins
    listing_revision_id          = null;
    // general
    bm_layer_id                  = null;
    application_id               = null;
    material_type_id             = null;
    layer_thickness              = null;
    // construction
    lot_construction_id          = null;
    o_e_lot_construction_data_id = null;
    pk_s                         = null;
    pk_f                         = null;
    pks_s                        = null;
    pks_f                        = null;
    pkf_s                        = null;
    pkf_f                        = null;
    track_id                     = null;
    axis_distance                = null;
    // validate and verify
    validated_by                 = null;
    validated_at                 = null;
    verified_inspection_by       = null;
    verified_inspection_at       = null;
    verified_concessionary_by    = null;
    verified_concessionary_at    = null;
}
