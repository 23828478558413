import { DialogController }                                     from 'aurelia-dialog';
import { bindable, inject }                                     from 'aurelia-framework';
import { PLATFORM }                                             from 'aurelia-pal';
import { BaseFormViewModel }                                    from 'base-form-view-model';
import { ManufacturingPlantTranspositionCompositionFormSchema } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/form-schema';
import { ManufacturingPlantTranspositionCompositionRepository } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/services/repository';
import { AppContainer }                                         from 'resources/services/app-container';

@inject(AppContainer, DialogController, ManufacturingPlantTranspositionCompositionRepository, ManufacturingPlantTranspositionCompositionFormSchema)
export class CreateManufacturingPlantTranspositionFractionModal extends BaseFormViewModel {

    formId = 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.creation-form';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    bmManufacturingPlantTranspositionId;

    @bindable settings = {
        title:   'form.title.create-new-record',
        size:    'modal-md',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.manage',
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.bmManufacturingPlantTranspositionId = params.bmManufacturingPlantTransposition.id;

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create({ ...this.model, bm_manufacturing_plant_transposition_id: this.bmManufacturingPlantTranspositionId })
            .then((response) => {
                this.handleResponse(response);

                if (response.status === true) {
                    this.initialModel.assign(this.model);
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

}
