import { BaseTestHeader } from 'modules/aggregates/models/base-test-header';

export class BMLigand extends BaseTestHeader {
    id                        = null;
    ligand_type               = null;
    central_number            = null;
    central_designation       = null;
    supplier                  = null;
    ligand_application        = null;
    validated_by              = null;
    validated_at              = null;
    verified_inspection_by    = null;
    verified_inspection_at    = null;
    verified_concessionary_by = null;
    verified_concessionary_at = null;
}
